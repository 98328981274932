import React, { Component } from "react"
import { Index } from "elasticlunr"
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from "prop-types"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from 'gatsby-link';
import LazyLoad from 'react-lazyload';
import { withStyles } from '@material-ui/core/styles';
import SectionCard from '../components/SectionCard';
import Autor from '../components/Autor.js';
import moment from 'moment';
import SearchInput from './SearchInput';

const styles = theme => ({
  card: {
    display: "flex",
  },
  cardContent: {
    paddingTop: "0",
    [theme.breakpoints.up('md')]: {
      marginLeft:"2.5rem",
    },
  },
  media: {
    width:"8rem",
    height:"5rem",
    [theme.breakpoints.up('md')]: {
    height:"12rem",
    width:"22rem",
      //height:"100%",
    },
  },
  boxIMG:{
    position:"relative",
  },
  escribe:{
    marginTop:"0.8rem !important",
    color:"black",
    fontWeight:"bold !important",
    fontSize:"0.9rem !important",
    [theme.breakpoints.down('md')]: {
      fontSize:"0.8rem !important",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:"0.7rem !important",
    },
    //textShadow: "1px 1px #000",
  },
  tituloNota:{
    color:"black",
    fontFamily:"FuturaBold",
    textDecoration:"none",
    lineHeight:"1.4rem",
    fontSize:"1.2rem",
    [theme.breakpoints.up('md')]:{
      fontSize:"1.8rem",
    },
  },
  fecha:{
    display: 'block',
    fontSize:".7rem !important",
    fontWeight: "600",
    [theme.breakpoints.up('md')]:{
      marginBottom: "0.3rem",
    },
  },
  boxNota:{
    margin: "1rem",
    [theme.breakpoints.up('md')]: {
      margin: "1rem 3rem",
    },
  },
  boxSearch:{
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: "3rem",
    },
  },
});

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      results: [],
    }
  }

  componentDidMount() {
    const query = this.props.term;
    if(query !== "") {
      this.index = this.getOrCreateIndex();
  
      this.setState({
        query,
        results: this.index
          .search(query, { expand: true })
          .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      })
    }
  }

  componentDidUpdate() {
    if (this.props.term !== this.state.query) {
      this.index = this.getOrCreateIndex();
      this.setState({
        query: this.props.term,
        results: this.index
          .search(this.props.term, { expand: true })
          .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      })
    }
  }

  getOrCreateIndex = () => {
    if (this.index) {
      return this.index
    } else {
      return Index.load(this.props.searchIndex)
    }
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div style={{marginTop:"2rem"}} >
        <Box className={classes.boxSearch}>
          <SearchInput />
        </Box>
        <h2 className={classes.boxSearch}>Resultados para <span style={{color:"red"}}>{this.props.term}</span></h2>
        <Grid container>
          {this.state.results.sort((a,b) => new Date(b.date) - new Date(a.date)).map(page => (
          <Grid key={`nota-${page.id}`} item xs={12} md={12}>
            <Box className={classes.boxNota}>
                  <Card className={classes.card}>
                    <Box className={classes.boxIMG}>
                      {/* {
                        node.frontmatter.image && ( */}
                          <LazyLoad height={300}>
                            <CardMedia
                              className={classes.media}
                              //ponemos jpg por ahora pero van a ser png
                              image={`https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${page.slug}.png`}
                              title={page.title}
                            />
                          </LazyLoad>
                        {/* )
                      } */}
                      {/* {
                        node.frontmatter.image === null && <Img fluid={imgFluidDefault} className={classes.media} />
                      } */}
                    </Box>
                    <CardContent className={classes.cardContent}>
                      <time className={classes.fecha} dateTime={page.date}>
                        {  moment( moment.utc(page.date)).format('DD/MM/YYYY')}
                      </time>
                      <SectionCard label={page.seccion} customPos={true} customColor={false} customTextColor={true}/>
                      <Link className={classes.tituloNota} to={`/${page.seccion}/${page.slug}`}>
                        {page.title}
                      </Link>
                      <Autor names={page.author} searchCard={true} />
                    </CardContent>
                  </Card>
            </Box>
          </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchResults)