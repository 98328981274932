import React, { Component } from "react"
import Layout from "../components/layout";
import { Box } from "@material-ui/core";
import { graphql, StaticQuery } from 'gatsby';
import SearchResults from '../components/SearchResults';
import ClientOnly from '../components/ClientOnly'
import GSearch from '../components/GSearch'
import PropTypes from "prop-types"
import withLocation from "../HOCS/WithLocation"

// Search component
class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      term: ''
    }
  }

  componentWillMount() {
    this.setState({ term: this.props.search && this.props.search.q ? this.props.search.q : this.props.location.state ? this.props.location.state.term : "" });
  }

  componentDidUpdate() {
    if (this.props.search && this.props.search.q !== this.state.term) {
      this.setState({ term: this.props.search.q });
    }
  }

  render() {
    const term = this.state.term;
    return (
      <div>
        <Layout>
          <Box>
          <ClientOnly> 
            <GSearch /> 
          </ClientOnly>
            {/* <div>
              <StaticQuery
                query={graphql`
                  query SearchIndexQuery {
                    siteSearchIndex {
                      index
                    }
                  }
                `}
                render = { data => (
                  <SearchResults searchIndex={data.siteSearchIndex.index} term={term} />
                )
                }
              />
            </div> */}
          </Box>
        </Layout>
      </div>
    )
  }
}

Search.propTypes = {
  search: PropTypes.object,
}

export default withLocation(Search)