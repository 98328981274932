import React from "react" 
import { Helmet } from "react-helmet"

const GSearch = () => { 
    return ( 
        <div> 
            <Helmet> <script async src="https://cse.google.com/cse.js?cx=57bdeecc541489910" ></script> </Helmet> 
            <div className="gcse-search"></div> 
        </div> 
    ) 
} 

export default GSearch